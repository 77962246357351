import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';

import SiteTemplate from '../../components/pagesTemplate/SiteTemplate';
import { H3, Text } from '../../design/SharedComponents';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const load8 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border-radius: 50%;
  width: 3em;
  height: 3em;

  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.7em solid #6998c826;
  border-right: 0.7em solid #6998c826;
  border-bottom: 0.7em solid #6998c826;
  border-left: 0.7em solid #6998c8;
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 3em;
    height: 3em;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const AdminPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://unikovo-cms.herokuapp.com/')
      .then((response) => {
        setLoading(false);

        if (response.status === 200) {
          navigate('https://unikovo-cms.herokuapp.com/admin/content-manager');
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <SiteTemplate>
      <Container>
        <Row>
          <Loader />
          <H3 style={{ marginLeft: '15px' }}>
            {loading ? 'Loading...' : 'Redirecting...'}
          </H3>
        </Row>
        {loading && <Text>(Usually takes up to 20 seconds)</Text>}
      </Container>
    </SiteTemplate>
  );
};

export default AdminPage;
